import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import TrustPilotStars from "../TrustPilotStars/TrustPilotStars";

export type Review = {
  name: string,
  rating: number,
  title: string,
  body: string,
  avatar: string,
  uri: string,
  date: {
    year: number,
    month: number,
    day: number,
  },
};

type Props = {
  reviews: Array<Review>,
};

const TrustPilotReview = ({ review }: Props) => {
  let { name, rating, title, body, avatar, uri } = review;
  return (
    <Col className="TrustPilotReview">
      <Card>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text className="text-fade mb-2">{body}</Card.Text>
          <Row noGutters>
            <Col>
              <TrustPilotStars rating={rating} sm />
            </Col>
            <Col className="text-right">
              <a href={uri} target={"_blank"} title="Read more">
                More...
              </a>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="align-items-center review-details">
        <Col md="auto">
          <div className="avatar">
            <img src={avatar} alt={name} />
          </div>
        </Col>
        <Col className="reviewer-name" md="auto">
          {name}
        </Col>
        <Col className="review-date" md="auto">
          6 days ago {/*TODO*/}
        </Col>
      </Row>
    </Col>
  );
};

export default TrustPilotReview;
