import React from "react";
import { Col, Row } from "react-bootstrap";

type Props = {
  heading?: string,
  children?: React.node,
  fullWidth?: boolean,
};
const Article = ({ heading, children, fullWidth }: Props) => {
  return (
    <Col className="Article">
      <Row>
        {heading ? (
          <Col md={fullWidth ? 12 : 5} className="mt-5 fc-bsm-grey-2">
            {heading ? <h2>{heading}</h2> : null}
          </Col>
        ) : null}
        <Col className="mt-5">{children}</Col>
      </Row>
    </Col>
  );
};

export default Article;
