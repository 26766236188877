import React, { useEffect, useState } from "react";
import Reviews from "../Reviews/Reviews";
import axios from "axios";
import logo from "./assets/logo.svg";
import { formatReviews } from "./helpers";

const Feefo = () => {
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(10);
  const [totalReviews, setTotalReviews] = useState(0);

  //summary
  useEffect(() => {
    axios(
      "https://api.feefo.com/api/10/reviews/summary/all?merchant_identifier=the-aa-driving-school-and-bsm"
    ).then(result => {
      if (!result) return;
      const {
        data: {
          rating: { rating },
          meta: { count },
        },
      } = result;
      setRating(rating * 2);
      setTotalReviews(count);
    });
  }, []);

  //reviews
  useEffect(() => {
    axios(
      "https://api.feefo.com/api/10/reviews/all?merchant_identifier=the-aa-driving-school-and-bsm"
    ).then(result => {
      if (!result.data) return;
      setReviews(formatReviews(result.data.reviews));
    });
  }, []);

  return (
    <div className="Feefo">
      {reviews.length ? (
        <Reviews
          rating={rating}
          reviews={reviews}
          totalReviews={totalReviews}
          maxPages={5}
          logo={logo}
        />
      ) : null}
    </div>
  );
};

export default Feefo;
