import React from "react";
import placeholder from "./assets/placeholder.jpg";

type Props = {
  src: string,
  alt: string,
};
const FullWidthImage = (props: Props) => {
  return (
    <div
      className="FullWidthImage"
      style={{
        backgroundImage: `url(${props.src || placeholder})`,
      }}
    >
      <img
        alt=""
        {...{
          ...props,
          ...{ src: props.src || placeholder },
        }}
      />
    </div>
  );
};

export default FullWidthImage;
