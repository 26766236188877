export const formatReviews = reviews =>
  reviews.map(
    ({
      url,
      customer, //might not be set !!
      service: {
        title,
        review,
        created_at,
        rating: { rating },
      },
    }) => {
      return {
        name: customer ? customer.display_name : "Anonymous",
        rating: rating * 2,
        title: title,
        body: review,
        uri: url,
        date: Date.parse(created_at),
      };
    }
  );
