export const getGAClientId = () => {
  try {
    if (typeof document.cookie === "undefined") {
      return null;
    }

    const name = "_ga";
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([.$?*|{}([\]\\/+^])/g, "\\$1")}=([^;]*)`
      )
    );

    return matches ? decodeURIComponent(matches[1]) : null;
  } catch (e) {
    return null;
  }
};

export const getGA4ClientIdFromUtag = () => {
  if (
    typeof window?.utag_data !== "undefined" &&
    window.utag_data.ga_client_id
  ) {
    return window.utag_data.ga_client_id;
  }
  return null;
};

/**
 * Function to get the session id from the utag object;
 * Priority order: ut.session_id, tealium_session_id, cp.utag_main_ses_id, null
 * @returns {string | null} sessionId - the session id or null
 */
export const getSessionId = () => {
  const sessionId = window?.utag_data["ut.session_id"];
  const tealiumSessionid = window?.utag_data["tealium_session_id"];
  const cpSessionId = window?.utag_data["cp.utag_main_ses_id"];

  return sessionId || tealiumSessionid || cpSessionId || null;
};

/**
 * Function to get the campaign id from utag object
 * Priority order: cp.utm_id_session, qp.utm_id, null
 * @param values
 */

export const getCampaignId = () => {
  const utagData = window.utag?.data;
  if (!utagData) {
    return null;
  }

  return utagData["cp.utm_id_session"] || utagData["qp.utm_id"] || null;
};

/**
 * Function to get the campaign from utag object
 * Priority order: cp.utm_campaign_session, qp.utm_campaign, null
 * @param values
 */
export const getCampaign = () => {
  const utagData = window.utag?.data;
  if (!utagData) {
    return null;
  }
  return (
    utagData["cp.utm_campaign_session"] || utagData["qp.utm_campaign"] || null
  );
};

/**
 * Function to get the medium from utag object
 * Priority order: cp.utm_medium_session, qp.utm_medium, web
 * @param values
 */
export const getMedium = () => {
  const utagData = window.utag?.data;
  if (!utagData) {
    return "web";
  }
  return (
    utagData["cp.utm_medium_session"] || utagData["qp.utm_medium"] || "web"
  );
};

/**
 * Function to get the source from utag object
 * Priority order: cp.utm_source_cookie, qp.utm_source, dom.referrer, null
 * @param values
 */

export const getSource = () => {
  const utagData = window.utag?.data;
  if (!utagData) {
    return null;
  }
  return (
    utagData["cp.utm_source_cookie"] ||
    utagData["qp.utm_source"] ||
    utagData["dom.referrer"] ||
    null
  );
};

/**
 * Function to get the analytics info from utag object using helper methods
 * @returns {
 * campaignId: string | null,
 * campaign: string | null,
 * medium: string,
 * source: string | null
 * }
 */
export const getAnalyticsInfo = () => {
  const campaignId = getCampaignId();
  const campaign = getCampaign();
  const medium = getMedium();
  const source = getSource();

  return {
    campaignId,
    campaign,
    medium,
    source,
  };
};

export default {
  getGAClientId,
  getGA4ClientIdFromUtag,
  getSessionId,
  getAnalyticsInfo,
  getCampaignId,
  getCampaign,
  getMedium,
  getSource,
};
