// @flow
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import PaginationDots from "../Pagination/PaginationDots/PaginationDots";
import { Row, Col } from "react-bootstrap";
import PaginationArrows from "../PaginationArrows/PaginationArrows";
import Review from "./Review/Review";
import type { TReview } from "./Review/Review";

type Props = {
  rating: number,
  reviews: Array<TReview>,
  logo?: object,
  randomise?: boolean,
  totalReviews?: number,
  maxPages?: number,
  perPage?: number,
};

const Reviews = ({
  rating,
  reviews,
  logo,
  randomise = false,
  totalReviews = null,
  maxPages = 3,
  perPage = 2,
}: Props) => {
  const sortedReviews = (randomise
    ? reviews.sort(() => Math.random() - 0.5)
    : reviews
  )
    .slice(0, maxPages * perPage)
    .reverse();

  const [reviewsArray, setReviewsArray] = useState(sortedReviews);
  const [page, setPage] = useState(0);
  const numPages = Math.ceil(sortedReviews.length / perPage);

  const goToPage = page => {
    const newPage = page < 0 ? numPages - 1 : page > numPages - 1 ? 0 : page;
    setReviewsArray(
      sortedReviews.slice(newPage * perPage, (newPage + 1) * perPage)
    );
    setPage(newPage);
  };

  const leftClick = () => {
    goToPage(page - 1);
  };

  const rightClick = () => {
    goToPage(page + 1);
  };

  return (
    <div className="reviews-wrapper">
      <div className="Reviews">
        <Col md={10} className="reviews-container mt-5">
          <Row
            className={
              "align-items-end justify-content-center reviews-overview text-center"
            }
          >
            {logo && (
              <Col md="auto">
                <SVG className="reviews-logo" src={logo} />
              </Col>
            )}
            <Col md="auto" className="reviews-summary">
              <strong>Rated {Math.round(rating * 10) / 10}/10</strong>
              {totalReviews && <> based on {totalReviews} reviews for BSM</>}
            </Col>
          </Row>
          <Row className="my-4 justify-content-center">
            {reviewsArray.slice(0, perPage).map((review, i) => (
              <Review key={i} review={review} index={i} />
            ))}
          </Row>
          <Row className="justify-content-center align-items-center">
            <PaginationDots
              clickHandler={goToPage}
              number={numPages}
              active={page}
            />
          </Row>
        </Col>
        <PaginationArrows
          clickLeftCallback={leftClick}
          clickRightCallback={rightClick}
        />
      </div>
    </div>
  );
};

export default Reviews;
