import React, { Component } from "react";
import TemplateOne from "../templates/template";
import axios from "axios";

class Preview extends Component {
  state = {
    loading: false,
    error: false,
    data: null,
  };

  componentWillMount() {
    this.fetchData();
  }

  fetchData() {
    if (this.state.loading || typeof window === "undefined") return;
    let url = window.location.search.replace(/^(\?url=)/, "");
    if (!url) return;
    this.setState({ loading: true });
    axios
      .get(url)
      .then(data => {
        //optionally do some verification, here...

        this.setState({
          loading: false,
          data: data.data,
        });
      })
      .catch(error => {
        this.setState({ loading: false, error });
      });
  }

  render() {
    return (
      <>
        {this.state.data ? (
          <TemplateOne
            location={this.props.location}
            pageContext={this.state}
          />
        ) : null}
        <span
          className={"reload-button" + (this.state.loading ? " loading" : "")}
          onClick={() => (this.state.loading ? null : this.fetchData())}
        >
          {this.state.loading
            ? "Loading..."
            : this.state.error
            ? "Error!"
            : "Reload"}
        </span>
      </>
    );
  }
}

export default Preview;
