import React from "react";

type Props = {
  innerHtml?: string,
};
const BlogContent = ({ innerHtml }: Props) => {
  return (
    <div
      className="BlogContent"
      dangerouslySetInnerHTML={{ __html: innerHtml }}
    />
  );
};

export default BlogContent;
