import React, { useEffect, useRef } from "react";

type LatLng = {
  lat: Number,
  lng: Number,
};

type MapProps = {
  center: LatLng,
  zoom?: string,
  markers?: LatLng[]
};

const Map = ({ center, zoom = 13, markers = [] }: MapProps) => {
  const mapRef = useRef(null);
  const apiKey =
    typeof window !== "undefined" && window._env_.HERE_MAPS_BSM_API_KEY;

  useEffect(() => {
    const Here = window.H;
    if (!mapRef.current || !Here) return;

    const platform = new Here.service.Platform({
      apikey: apiKey,
      useHTTPS: true,
    });

    const defaultLayers = platform.createDefaultLayers();
    const map = new Here.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center,
      zoom,
      pixelRatio: window.devicePixelRatio || 1,
    });

    // add map markers
    markers.forEach(marker => {
      map.addObject(new Here.map.Marker(marker));
    })

    return () => {
      map.dispose();
    };
  }, [mapRef]);

  return <div ref={mapRef} className="here-map" />;
};

export default Map;