import React from "react";
import { Col, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";

export type TSlide = {
  svg?: string,
  imageUrl?: string,
  title?: string,
  body?: string,
};

type Props = {
  slide: TSlide,
  active: boolean,
};

const Slide = ({ slide: { svg, imageUrl, title, body }, active }: Props) => {
  return (
    <div className={"Slide" + (active ? " active" : "")}>
      <Row noGutters className="align-items-center">
        <Col md={1} />
        <Col className="carousel-image-col" md={5}>
          {svg ? <SVG className="carousel-image" src={svg} /> : null}
          {imageUrl && !svg ? (
            <img className="carousel-image" alt={title} src={imageUrl} />
          ) : null}
        </Col>
        <Col className="carousel-text-col" md={5}>
          <h6>{title}</h6>
          <p>{body}</p>
        </Col>
        <Col sm={1} />
      </Row>
    </div>
  );
};

export default Slide;
