import React from "react";
import PaginationDot from "../PaginationDot/PaginationDot";

type Props = {
  number: number,
  active: number,
  clickHandler?: Function,
};

const PaginationDots = ({ number, active, clickHandler }: Props) => (
  <ul className="PaginationDots">
    {Array.apply(null, { length: number }).map((n, i) => {
      return (
        <PaginationDot
          key={i}
          active={active === i}
          clickHandler={clickHandler ? clickHandler.bind(null, i) : null}
        />
      );
    })}
  </ul>
);

export default PaginationDots;
