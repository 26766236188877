// @flow
import React from "react";

type HtmlElement = {
  Tag: string,
  content: string,
  className: string,
};

type Props = {
  elements: HtmlElement[],
};

const HtmlContent = ({ elements }: Props) => {
  return (
    <>
      {elements.map(({ Tag, contents, className }, i) => (
        <Tag
          key={i}
          dangerouslySetInnerHTML={{ __html: contents }}
          className={className}
        />
      ))}
    </>
  );
};

export default HtmlContent;
