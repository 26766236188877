// @flow
import React, { useState, useEffect } from "react";
import ActionTile from "../ActionTile/ActionTile";
import type { TActionTile } from "../ActionTile/ActionTile";
import { Col, Row } from "react-bootstrap";

type Props = {
  tiles: Array<TActionTile>,
  columns?: number,
  total: number,
  randomize?: boolean,
  moreText?: string,
  moreAction?: string,
};
const ActionTiles = ({
  tiles = [],
  columns = 4,
  total = 8,
  randomize = true,
  moreText,
  moreAction,
}: Props) => {
  const [renderedTiles, setRenderedTiles] = useState();
  useEffect(() => {
    setRenderedTiles(randomize ? tiles.sort(() => Math.random() - 0.5) : tiles)
  }, []);

  return (
    <Col sm={12} className="ActionTiles">
      <Row noGutters>
        {renderedTiles && renderedTiles.filter(
            (a, i) =>
              !total ||
              i < (moreText && renderedTiles.length >= total ? total - 1 : total)
          )
          .map((tile, i) => (
            <Col md={12 / columns} key={i} className="action-tile-col">
              <ActionTile {...tile} />
            </Col>
          ))}
        {!!moreText && (
          <Col md={12 / columns} className="action-tile-col">
            <ActionTile text={moreText} action={moreAction} />
          </Col>
        )}
      </Row>
    </Col>
  );
};
export default ActionTiles;
