import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ReviewStars from "../ReviewStars/ReviewStars";
import Frame from "react-frame-component";
import { renderToString } from "react-dom/server";
import { reviewDateString } from "./helpers";

export type TReview = {
  name: string,
  rating: number,
  title: string,
  body: string,
  uri: string,
  date: Date,
};

type Props = {
  review: Array<TReview>,
  index: number,
  perPage: number,
};

/* 🤮
I don't know how critical this is, but I read somewhere that we should try
to render our review content inside an iframe so we don't get penalised for
duplicate content (with the review provider). Unfortunately, this means we
need to bootstrap the frame with some basic styles and import the required
fonts. If anybody can think of a better way to do this, I'd be very happy
to hear it.
 */
const initialContent = renderToString(
  <html>
    <head>
      <style
        dangerouslySetInnerHTML={{
          __html: `html,body,*{
            font-family:"Titillium Web", sans-serif;
            padding:0;
            margin:0;
            line-height:1.5em;
            overflow:hidden;
        }`,
        }}
      />
      <link rel="stylesheet" href="https://use.typekit.net/cam1cfl.css" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Titillium+Web:400,400i,600&amp;display=swap"
        media="all"
      />
    </head>
    <body />
  </html>
);

const Review = ({ review, index, perPage = 2 }: Props) => {
  const { name, rating, title, body, uri, date } = review;
  const clickHandler = () => (uri ? window.open(uri, "_blank") : null);

  return (
    <Col
      md={12 / perPage}
      className={`Review ${index > 0 ? "d-none d-md-block" : ""}`}
    >
      <Card>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text className="text-fade mb-2">
            <Frame mountTarget="body" initialContent={initialContent}>
              <div>{body}</div>
            </Frame>
          </Card.Text>
          <Row noGutters className="review-details">
            <Col className="rating" xs="auto">
              <ReviewStars rating={rating} size="small" />
            </Col>
            <Col className="reviewer-name">{name}</Col>
            <Col className="date" xs="auto">
              {reviewDateString(date)}
            </Col>
          </Row>
        </Card.Body>
        <div className="click-capture" onClick={clickHandler} />
      </Card>
    </Col>
  );
};

export default Review;
