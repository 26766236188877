import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { trackEvent } from "../../utils/tealium";
import { capitalizeEachWordOfPathname } from "../../utils/capitalisePathname";

type Props = {
  buttonText?: string,
  buttonAction?: string,
};
const Banner = ({ buttonText, buttonAction }: Props) => {
  return buttonText ? (
    <Row className="Banner justify-content-center align-items-center">
      <Col>
        <Button variant="info" href={buttonAction} onClick={() => {
          trackEvent({
            eventCategory: capitalizeEachWordOfPathname(window.location.pathname),
            eventAction: "Button Click",
            eventLabel: "Book A Lesson",
          });
        }}>
          {buttonText}
        </Button>
      </Col>
    </Row>
  ) : null;
};

export default Banner;
