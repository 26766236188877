const getDate = date => {
  const parts = date.match(
    /^([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})$/
  );
  return new Date(parts[1], parts[2], parts[3], parts[4], parts[5], parts[6]);
};

export const formatReviews = reviews =>
  reviews.map(
    ({
      reviewer: { first_name, last_name },
      rating,
      title,
      comments,
      store_review_id,
      date_created,
    }) => ({
      name: `${first_name ? first_name : ""} ${
        last_name ? last_name : ""
      }`.trim(),
      rating: rating * 2,
      title: title
        ? title
        : comments
            .split(/\s+/)
            .slice(0, 3)
            .join(" ") + "...",
      body: comments,
      uri: `https://www.reviews.co.uk/company-review/store/bsm-co-uk/${store_review_id}`,
      date: getDate(date_created),
    })
  );
