import React from "react";

type Props = {
  active: boolean,
  clickHandler?: Function,
};

const PaginationDot = ({ active, clickHandler }: Props) => (
  <li
    className={"PaginationDot" + (active ? " active" : "")}
    onClick={clickHandler}
  ></li>
);

export default PaginationDot;
