import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Tarot from "../Tarot/Tarot";
import { trackEvent } from "../../utils/tealium";
import { capitalizeEachWordOfPathname } from "../../utils/capitalisePathname";

type TTarot = {
  image?: string,
  title?: string,
  bodyParagraphs?: string[],
  buttonText?: string,
  buttonAction?: string,
};
type Props = {
  cards: TTarot[],
  buttonText?: string,
  buttonAction?: string,
};

const TarotCards = ({ cards, buttonText, buttonAction }: Props) => {
  return (
    <Col className="TarotCards">
      <Row>
        {cards.map(
          ({ image, title, bodyParagraphs, buttonText, buttonAction }, i) => {
            return (
              <Tarot key={i} image={image} count={cards.length} title={title}>
                <Row className={buttonText ? "card-has-button" : ""}>
                  <Col>
                    {bodyParagraphs.map((p, i) => (
                      <p key={i} dangerouslySetInnerHTML={{ __html: p }} />
                    ))}
                  </Col>
                </Row>
                {buttonText ? (
                  <Row className="card-button-row">
                    <Col className="text-center mt-3">
                      <Button variant="secondary" href={buttonAction}>
                        {buttonText}
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </Tarot>
            );
          }
        )}
      </Row>
      {buttonText ? (
        <Row>
          <Col className="text-center mt-5">
            <Button variant="secondary" href={buttonAction} onClick={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(window.location.pathname),
                eventAction: "Button Click",
                eventLabel: "Get Prices",
              });
            }}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      ) : null}
    </Col>
  );
};

export default TarotCards;
