import React from "react";
import { GraphQLClient, ClientContext } from "graphql-hooks";

export function ClientContextProvider({ children }) {
  const client = new GraphQLClient({
    url:
      typeof window !== "undefined"
        ? window._env_.GATEWAY_GRAPHQL_ENDPOINT
        : "http://localhost:4010/graphql",
  });

  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  );
}
