import React from "react";
import { Col, Row } from "react-bootstrap";
import Avatar from "../Avatar/Avatar";

type Props = {
  author?: string,
  title?: string,
  date?: string, //todo
};
const BlogMeta = ({ author, title, date }: Props) => {
  return (
    <Col className="BlogMeta">
      <Row className="align-items-center">
        <Col md="auto" sm="auto">
          <Avatar name={author} size="md" />
        </Col>
        <Col>
          <Row className="written-by">
            <Col>
              <span>Written by</span>
            </Col>
          </Row>
          <Row className="align-items-baseline">
            <Col md="auto" sm="auto" className="author">
              <span>{author}</span>
            </Col>
            <Col className="title">{title}</Col>
            <Col md="auto" sm="auto">
              <span>{date}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default BlogMeta;
