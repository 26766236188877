/*global Sentry*/
import React, { Fragment, useEffect, useState } from "react";
import queryString from "query-string";
import ButtonTabs from "../ButtonTabs/ButtonTabs";
import ButtonTab from "../ButtonTab/ButtonTab";
import { Button, Col } from "react-bootstrap";
import Form from "../Form/Form";
import { TTextInput } from "../Form/TextInput/TextInput";
import TextInput from "../Form/TextInput/TextInput";
import { navigate } from "gatsby";
import axios from "axios";
import { trackEvent } from "../../utils/tealium";
import { getAnalyticsInfo, getGAClientId, getGA4ClientIdFromUtag, getSessionId } from "../../utils/ga-client";

import { useMutation } from "graphql-hooks";

const createEnquiryLeadMutation = `mutation CreateEnquiryLead($input: CreateEnquiryLeadInput!) {
  createEnquiryLead(input: $input)
}`;

type TContactOption = {
  buttonText: string,
  phoneHeading: string,
  phoneNumber: string,
  phoneHours: string[],
  phoneButton?: string,
  actionHeadingLeft: string,
  actionTextLeft: string,
  actionTypeLeft?: string,
  actionFormEventActionLeft?: string,
  actionButtonTextLeft?: string,
  actionButtonActionLeft?: string,
  actionButtonEventActionLeft?: string,
  actionFormTypeLeft: "INSTRUCTOR" | "PUPIL",
  actionHeadingRight: string,
  actionTextRight: string,
  actionTypeRight?: string,
  actionFormEventActionRight?: string,
  actionButtonTextRight?: string,
  actionButtonActionRight?: string,
  actionButtonEventActionRight?: string,
  actionFormTypeRight: "INSTRUCTOR" | "PUPIL",
};

type Props = {
  contactOptions: Array<TContactOption>,
  location?: Location,
};

const ActionPane = ({
  actionHeading,
  actionText,
  actionType,
  actionButtonText,
  actionButtonAction,
  actionFormEventAction,
  actionButtonEventAction,
  buttonText,
  handleSubmit,
  handleButtonClick,
  loading,
  formType,
}) => {
  const formInputs = getFormInputs(formType);

  let enquiryType;
  switch (actionFormEventAction) {
    case "IG Form":
      enquiryType = "IG";
      break;
    case "DIT Form":
      enquiryType = "DIT";
      break;
    default:
      enquiryType = null;
      break;
  }

  return (
    <Fragment>
      <h2>{actionHeading}</h2>
      <p>{actionText}</p>
      {actionType === "form" && (
        <Form
          inputs={formInputs}
          loading={loading}
          submitText="Send message"
          submitAction={handleSubmit(formType, enquiryType)}
          submitEventAction={actionFormEventAction}
          isFranchiseePrivacyNotice={enquiryType === "IG"}
        />
      )}
      {actionType === "button" && (
        <p>
          <Button
            variant="secondary"
            href={actionButtonAction}
            data-test="contact-booking-button"
            onClick={() => handleButtonClick(actionButtonEventAction)}
          >
            {actionButtonText}
          </Button>
        </p>
      )}
    </Fragment>
  );
};

const ContactOptions = ({ contactOptions = [], location }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const queryParams = queryString.parse((location && location.search) || "");
  const activeTab = queryParams.active || 0;

  const [
    createEnquiryLead,
    { loading: enquiryLeadLoading, error: enquiryLeadError },
  ] = useMutation(createEnquiryLeadMutation);

  useEffect(() => {
    if (enquiryLeadError && enquiryLeadError.graphQLErrors) {
      setError(enquiryLeadError.graphQLErrors[0].message);
      Sentry.captureException(enquiryLeadError);
      setLoading(false);
    }
  }, [enquiryLeadError]);

  const handleButtonClick = submitID => {
    trackEvent({
      eventCategory: "Contact",
      eventAction: submitID,
      eventLabel: "Submit",
    });
  };

  const handleButtonClickCall = submitEventAction => {
    trackEvent({
      eventCategory: "Contact",
      eventAction: submitEventAction,
      eventLabel: "Call",
    });
  };

  const submitAction = (formType, enquiryType) => (event, values) => {
    setError(false);
    values.type = formType;
    values.clientId = getGAClientId();

    setLoading(true);

    if (formType === "INSTRUCTOR") {
      const ga4ClientId = getGA4ClientIdFromUtag();
      const sessionId = getSessionId();
      const { campaign, campaignId, medium, source } = getAnalyticsInfo();

      const createEnquiryLeadInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        postcode: values.postcode,
        emailAddress: values.email,
        mobileNumber: values.phone,
        brand: "BSM",
        channel: "ContactForm",
        enquiryType: enquiryType,
        clientId: ga4ClientId,
        analytics: {
          campaign,
          campaignId,
          medium,
          sessionId,
          source,
        },
      };

      try {
        createEnquiryLead({
          variables: {
            input: createEnquiryLeadInput,
          },
          onSuccess: () => {
            setLoading(false);
            navigate("/thanks");
          },
        });
      } catch {
        setLoading(false);
        setError(enquiryLeadError);
        Sentry.captureException(error);
      }
    } else {
      const url = window._env_.GATSBY_CONTACT_FORM_LAMBDA_URI;

      axios
        .post(url, values)
        .then(() => {
          setLoading(false);
          navigate("/thanks");
        })
        .catch(error => {
          setLoading(false);
          setError(error);
          Sentry.captureException(error);
        });
    }
  };

  return (
    <ButtonTabs className="ContactOptions" activeTab={activeTab}>
      {contactOptions.map(
        (
          {
            buttonText,
            phoneHeading,
            phoneNumber,
            phoneHours,
            phoneButton = null,
            actionHeadingLeft,
            actionTypeLeft,
            actionTextLeft,
            actionButtonTextLeft,
            actionButtonActionLeft,
            actionButtonEventActionLeft,
            actionFormEventActionLeft,
            actionFormTypeLeft,
            actionHeadingRight,
            actionTypeRight,
            actionTextRight,
            actionButtonTextRight,
            actionButtonActionRight,
            actionButtonEventActionRight,
            actionFormEventActionRight,
            actionFormTypeRight,
          }: TContactOption,
          i
        ) => (
          <ButtonTab key={i} buttonText={buttonText}>
            <Col xs={12} md={6} lg={5} className="button-tab-top">
              <h2>{phoneHeading}</h2>
              {phoneNumber && (
                <h5>
                  Call&nbsp;
                  <a
                    href={`tel:+44${phoneNumber}`}
                    className="clickable-phone-number"
                    onClick={() =>
                      handleButtonClickCall(actionFormEventActionRight)
                    }
                  >
                    {phoneNumber}
                  </a>
                </h5>
              )}
              {phoneHours.map((p, i) => (
                <p key={i} className="phone-hours">
                  {p}
                </p>
              ))}
              {phoneButton ? (
                <Button
                  variant="secondary"
                  href={`tel:+44${phoneNumber}`}
                  data-test="contact-call-button"
                >
                  {phoneButton}
                </Button>
              ) : null}

              {actionHeadingLeft ||
              actionTextLeft ||
              actionTypeLeft === "form" ||
              actionButtonTextLeft ? (
                <div className="action-pane-left">
                  <ActionPane
                    actionHeading={actionHeadingLeft}
                    actionText={actionTextLeft}
                    actionType={actionTypeLeft}
                    actionButtonText={actionButtonTextLeft}
                    actionButtonAction={actionButtonActionLeft}
                    actionFormEventAction={actionFormEventActionLeft}
                    actionButtonEventAction={actionButtonEventActionLeft}
                    handleSubmit={submitAction}
                    handleButtonClick={handleButtonClick}
                    loading={loading}
                    formType={actionFormTypeLeft}
                  />
                </div>
              ) : null}
            </Col>

            {actionHeadingRight ||
            actionTextRight ||
            actionTypeRight === "form" ||
            actionButtonTextRight ? (
              <Col xs={12} md={6} lg={{ span: 6, offset: 1 }}>
                <ActionPane
                  actionHeading={actionHeadingRight}
                  actionText={actionTextRight}
                  actionType={actionTypeRight}
                  actionButtonText={actionButtonTextRight}
                  actionButtonAction={actionButtonActionRight}
                  actionFormEventAction={actionFormEventActionRight}
                  actionButtonEventAction={actionButtonEventActionRight}
                  buttonText={buttonText}
                  handleSubmit={submitAction}
                  handleButtonClick={handleButtonClick}
                  loading={loading || enquiryLeadLoading}
                  formType={actionFormTypeRight}
                />
              </Col>
            ) : null}
          </ButtonTab>
        )
      )}
    </ButtonTabs>
  );
};

export default ContactOptions;

const getFormInputs: Array<TTextInput> = formType => {
  const inputs = [
    {
      label: "Your email",
      icon: "envelope",
      name: "email",
      InputType: TextInput,
      type: "email",
      required: true,
      "data-test": "contact-form-email",
      title: "Please enter a valid email address",
    },
    {
      label: "Your mobile",
      icon: "phone",
      name: "phone",
      InputType: TextInput,
      type: "tel",
      required: true,
      "data-test": "contact-form-tel",
      pattern: "(07\\d{9}|447\\d{9}|[+]447\\d{9})$",
      title: "Please enter a valid mobile or landline number",
    },
  ];

  // Pupil form does not require postcode
  if (formType === "INSTRUCTOR") {
    inputs.unshift(
      {
        label: "Your first name",
        placeholder: "",
        icon: "face",
        name: "firstName",
        InputType: TextInput,
        required: true,
        "data-test": "contact-form-first-name",
        minLength: 2,
        title: "Please enter your first name",
      },
      {
        label: "Your last name",
        placeholder: "",
        icon: "face",
        name: "lastName",
        InputType: TextInput,
        required: true,
        "data-test": "contact-form-last-name",
        minLength: 2,
        title: "Please enter your last name",
      }
    );
    inputs.push({
      label: "Your postcode",
      icon: "location",
      name: "postcode",
      InputType: TextInput,
      required: true,
      "data-test": "contact-form-postcode",
      pattern: "^[A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}$",
      title: "Please enter a valid UK postcode",
    });
  } else {
    inputs.unshift({
      label: "Your name",
      placeholder: "",
      icon: "face",
      name: "name",
      InputType: TextInput,
      required: true,
      "data-test": "contact-form-name",
    });
    inputs.push({
      label: "Your message",
      icon: "speech",
      name: "message",
      placeholder: "",
      InputType: TextInput,
      "data-test": "contact-form-message",
    });
  }

  return inputs;
};
