import React, { Component } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import type { TTextInput } from "./TextInput/TextInput";
import { trackEvent } from "../../utils/tealium";

type Props = {
  inputs: Array<TTextInput>,
  submitAction: Function,
  submitText: string,
  submitEventAction?: string,
  children?: React.node,
  loading: Boolean,
  isFranchiseePrivacyNotice: Boolean,
};

class Form extends Component {
  constructor(props: Props) {
    super(props);
    let values = {};
    for (let i = 0; i < props.inputs.length; i++) {
      let { name, value } = props.inputs[i];
      values[name] = value;
    }
    this.state = values;
  }

  handleInputChanged(e) {
    let { name, value } = e.target;
    let values = {};
    values[name] = value;
    this.setState(values);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.props.submitAction) {
      trackEvent({
        eventCategory: "Contact",
        eventAction: this.props.submitEventAction,
        eventLabel: "Submit",
      });
      this.props.submitAction(e, this.state);

      //Bing custom event
      window.uetq = window.uetq || [];
      window.uetq.push("event", this.props.submitEventAction, {
        event_category: "Contact",
        event_label: "Submit",
        event_value: "0",
      });

      //FB custom event
      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq("track", "Lead", {
            content_name: "BSM Form Fill",
            content_category: this.props.submitEventAction,
          });
        }
      }

      //LinkedIn custom event (only for IG)
      if (this.props.submitEventAction === "IG Form") {
        window.lintrk("track", { conversion_id: 5185657 });
      }
    }
  }

  render() {
    let { inputs, submitAction, submitText, children } = this.props;
    return (
      <form className="Form" onSubmit={e => this.handleSubmit(e)}>
        {children ? (
          <Row className="mb-3">
            <Col sm={12} md={12}>
              {children}
            </Col>
          </Row>
        ) : null}

        <Row className="mt-0 mb-3">
          {inputs.map(
            (
              { label, placeholder, icon, name, InputType, ...restProps },
              i
            ) => {
              return (
                <Col key={i} sm={12} md={12} className="my-3">
                  <InputType
                    sm
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    icon={icon}
                    onChange={this.handleInputChanged.bind(this)}
                    {...restProps}
                  />
                </Col>
              );
            }
          )}
        </Row>
        <p style={{ marginBottom: 0 }}>
          By providing this information you agree to The AA Group of companies
          using it for administrative purposes. Our{" "}
          <a
            href={
              this.props.isFranchiseePrivacyNotice
                ? "https://www.bsm.co.uk/franchisee-privacy-notice"
                : "https://www.bsm.co.uk/privacy-notice"
            }
          >
            privacy notice
          </a>{" "}
          explains how and why we use your personal information, including what
          details we hold, who we might share it with, and your choices and
          rights. It also includes information about any checks and decisions we
          may make.
        </p>
        <Row className="justify-content-center align-items-center">
          <Button
            className="form-submit-button"
            data-test="form-submit-button"
            variant="secondary"
            type="submit"
            disabled={this.props.loading}
          >
            {this.props.loading ? (
              <Spinner animation="border" />
            ) : submitText ? (
              submitText
            ) : (
              "Submit"
            )}
          </Button>
        </Row>
      </form>
    );
  }
}

export default Form;
