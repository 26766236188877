import React from "react";
import SVG from "react-inlinesvg";
import PaginationDots from "../Pagination/PaginationDots/PaginationDots";
import logo from "./assets/svg/logo-black.svg";
import { Row, Col } from "react-bootstrap";
import TrustPilotReview from "../TrustPilotReview/TrustPilotReview";
import type { Review } from "../TrustPilotReview/TrustPilotReview";
import TrustPilotStars from "../TrustPilotStars/TrustPilotStars";
import PaginationArrows from "../PaginationArrows/PaginationArrows";

type Props = {
  reviews: Array<Review>,
  rating: number,
};

const TrustPilot = ({ rating, reviews }: Props) => (
  <>
    <Col md={10} className="TrustPilot mt-5">
      <Row className="align-items-end justify-content-center trustpilot-overview">
        <Col md="auto">
          <SVG className="trustpilot-logo" src={logo} />
        </Col>
        <Col md="auto">
          <TrustPilotStars rating={rating} />
        </Col>
        <Col md="auto">
          <p>
            <strong>Rated {rating}</strong>/10 based on over 400 reviews for BSM
          </p>
        </Col>
      </Row>
      <Row className="my-4">
        {reviews.map((review, i) => (
          <TrustPilotReview key={i} review={review} />
        ))}
      </Row>
      <Row className="justify-content-center align-items-center">
        <PaginationDots number={3} active={1} />
      </Row>
    </Col>
    <PaginationArrows /> {/*todo hook up pagination*/}
  </>
);

export default TrustPilot;
