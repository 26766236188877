import React from "react";
import SVG from "react-inlinesvg";
import { svg } from "react-dom";
import { Col, Row } from "react-bootstrap";

type TExplainerStep = {
  imageUrl?: string,
  svg?: React.ComponentType<svg>,
  title?: string,
  body: string,
};

type Props = {
  step: TExplainerStep,
  position: number,
};

const ExplainerStep = ({
  step: { imageUrl, svg, title, body },
  position,
}: Props) => {
  return (
    <Row noGutters className="ExplainerStep">
      <Col md={2} sm={3} xs={4} className="explainer-step-image">
        <div
          className="explainer-step-square"
          style={{ backgroundImage: `url(${svg ? svg : imageUrl})` }}
        >
          {svg ? <SVG src={svg} /> : null}
          {!svg && imageUrl ? <img src={imageUrl} alt={title} /> : null}
        </div>
      </Col>
      <Col md={9} sm={9} xs={8} className="explainer-step-text">
        <h6>
          {position + 1}. {title}
        </h6>
        <p>{body}</p>
      </Col>
    </Row>
  );
};

export default ExplainerStep;
