// @flow
import React from "react";
import type { TInstructor } from "../Instructor/Instructor";
import { Col, Row } from "react-bootstrap";
import Instructor from "../Instructor/Instructor";

type Props = {
  title?: string,
  instructor: TInstructor,
  children?: React.node,
};
const InstructorBiography = ({ title, instructor, children }: Props) => {
  return (
    <Col className="InstructorBiography">
      {/*<Row noGutters>*/}
      {/*  <Col sm={5}>*/}
      {/*    <Instructor {...instructor} />*/}
      {/*  </Col>*/}
      {/*  <Col sm={1} />*/}
      {/*  <Col>*/}
      {/*    <h2>{title}</h2>*/}
      {/*    {React.Children.toArray(children)*/}
      {/*      .filter(child => child.type === "p")*/}
      {/*      .map(child => child)}*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/* Switch back when instructor biographies are populated*/}
      <Row noGutters>
        <Col sm={2} md={3} className={"d-xs-none"} />
        <Col xs={12} sm={8} md={6}>
          <Instructor {...instructor} />
        </Col>
        <Col sm={2} md={3} className={"d-xs-none"} />
      </Row>
    </Col>
  );
};

export default InstructorBiography;
