import React from "react";

type Props = {
  buttonText: string,
  children?: React.node,
};
const ButtonTab = ({ children }: Props) => {
  return children ? children : <></>;
};

export default ButtonTab;
