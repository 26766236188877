import React from "react";
import SVG from "react-inlinesvg";
import arrowLeft from "./assets/arrow-left.svg";
import arrowRight from "./assets/arrow-right.svg";

type Props = {
  clickLeftCallback: Function,
  clickRightCallback: Function,
  allowFullWidth?: boolean,
};

const PaginationArrows = ({
  clickLeftCallback,
  clickRightCallback,
  allowFullWidth,
}: Props) => (
  <div className="PaginationArrows">
    <div
      className={
        allowFullWidth ? "arrow-container" : "arrow-full-width-container"
      }
    >
      <div className="left" onClick={clickLeftCallback}>
        <SVG src={arrowLeft} />
      </div>
      <div className="right" onClick={clickRightCallback}>
        <SVG src={arrowRight} />
      </div>
    </div>
  </div>
);

export default PaginationArrows;
