// @flow
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import arrow from "./assets/arrow.svg";
import InlineSVG from "react-inlinesvg";
import SafeLink from "../SafeLink/SafeLink";

export type TActionTile = {
  text: string,
  action: string,
};
const ActionTile = ({ text, action }: TActionTile) => {
  return (
    <SafeLink className="ActionTile" to={action}>
      <Card.Body>
        <Row>
          <Col className="text">{text}</Col>
          <Col xs="auto">
            <InlineSVG src={arrow} />
          </Col>
        </Row>
      </Card.Body>
    </SafeLink>
  );
};

export default ActionTile;
