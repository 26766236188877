import React from "react";
import { Star, StarBorder, StarHalf } from "@material-ui/icons";

type Props = {
  rating?: number,
  size?: "small" | "large" | "default",
};

const ReviewStars = ({ rating = 0, size = "default" }: Props) => {
  return (
    <div className={`ReviewStars stars-${size}`}>
      {Array.apply(null, { length: 5 }).map((item, i) => {
        let test = Math.round(rating) / 2;
        return (
          <div key={i} className="rating-star">
            {test - i >= 1 ? (
              <Star fontSize={size} />
            ) : test - i <= 0 ? (
              <StarBorder fontSize={size} />
            ) : (
              <StarHalf fontSize={size} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReviewStars;
