import React from "react";
import { Col, Row } from "react-bootstrap";
import BulletListItem from "../BulletListItem/BulletListItem";
import type { TBulletListItem } from "../BulletListItem/BulletListItem";

type Props = {
  title: string,
  bulletListItems: TBulletListItem[],
};

const BulletList = ({ title, bulletListItems }: Props) => (
  <Col className="BulletList mb-5" md={10}>
    <h4 className="mb-3" dangerouslySetInnerHTML={{ __html: title }} />
    <Row className="justify-content-center">
      {bulletListItems.map(({ title, bodyParagraphs }, i) => (
        <BulletListItem key={i} title={title} bodyParagraphs={bodyParagraphs} />
      ))}
    </Row>
  </Col>
);

export default BulletList;
