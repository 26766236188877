import React from "react";
import { Col } from "react-bootstrap";

export type TBulletListItem = {
  title: string,
  bodyParagraphs: Array<string>,
};

type Props = TBulletListItem;

const BulletListItem = ({ title, bodyParagraphs }: Props) => (
  <Col className="BulletListItem my-3" md={6}>
    <h5>{title}</h5>
    {bodyParagraphs.map((p, i) => (
      <p key={i} dangerouslySetInnerHTML={{ __html: p }} />
    ))}
  </Col>
);

export default BulletListItem;
