import React from "react";
import Template from "../components/Template/Template";
import Section from "../components/Section/Section";
import Hero from "../components/Hero/Hero";
import Carousel from "../components/Carousel/Carousel";
import TrustPilot from "../components/TrustPilot/TrustPilot";
import Explainer from "../components/Explainer/Explainer";
import Article from "../components/Article/Article";
import TarotCards from "../components/TarotCards/TarotCards";
import BulletList from "../components/BulletList/BulletList";
import Banner from "../components/Banner/Banner";
import ContactOptions from "../components/ContactOptions/ContactOptions";
import InstructorHero from "../components/InstructorHero/InstructorHero";
import InstructorBiography from "../components/InstructorBiography/InstructorBiography";
import MapList from "../components/MapList/MapList";
import ActionTiles from "../components/ActionTiles/ActionTiles";
import BlogMeta from "../components/BlogMeta/BlogMeta";
import FullWidthImage from "../components/FullWidthImage/FullWidthImage";
import BlogContent from "../components/BlogContent/BlogContent";
import BlogList from "../components/BlogList/BlogList";
import Instructors from "../components/Instructors/Instructors";
import HtmlContent from "../components/HtmlContent/HtmlContent";
import Feefo from "../components/Feefo/Feefo";
import ReviewsIo from "../components/ReviewsIo/ReviewsIo";
import { ClientContextProvider } from "../gql/client";

const TemplateOne = ({ location, pageContext, pageContext: { data, seo } }) => (
  <Template {...data.props} seo={seo} location={location}>
    {data.sections.map(({ props, component }, i) => (
      <Section key={i} {...props}>
        {component.component === "Hero" ? <Hero {...component.props} /> : null}

        {component.component === "Carousel" ? (
          <Carousel {...component.props} />
        ) : null}

        {component.component === "Trustpilot" ? (
          <TrustPilot {...component.props} />
        ) : null}

        {component.component === "Explainer" ? (
          <Explainer {...component.props}>
            <HtmlContent elements={component.children} />
          </Explainer>
        ) : null}

        {component.component === "Article" ? (
          <Article {...component.props}>
            <HtmlContent elements={component.children} />
          </Article>
        ) : null}

        {component.component === "TarotCards" ? (
          <TarotCards {...component.props} />
        ) : null}

        {component.component === "BulletList" ? (
          <BulletList {...component.props} />
        ) : null}

        {component.component === "Banner" ? (
          <Banner {...component.props} />
        ) : null}

        {component.component === "Contact" ? (
          // todo  mismatch of names ContactOptions and Contact
          <ClientContextProvider>
            <ContactOptions {...component.props} location={location} />
          </ClientContextProvider>
        ) : null}

        {component.component === "InstructorHero" ? (
          <InstructorHero {...component.props} />
        ) : null}

        {component.component === "InstructorBiography" ? (
          <InstructorBiography {...component.props}>
            <HtmlContent elements={component.children} />
          </InstructorBiography>
        ) : null}

        {component.component === "MapList" ? (
          <MapList {...component.props} />
        ) : null}

        {component.component === "ActionTiles" ? (
          <ActionTiles {...component.props} />
        ) : null}

        {component.component === "BlogMeta" ? (
          <BlogMeta {...component.props} />
        ) : null}

        {component.component === "FullWidthImage" ? (
          <FullWidthImage {...component.props} />
        ) : null}

        {component.component === "BlogContent" ? (
          <BlogContent {...component.props} />
        ) : null}

        {component.component === "BlogList" ? (
          <BlogList {...component.props} />
        ) : null}

        {component.component === "Instructors" ? (
          <Instructors {...component.props} />
        ) : null}

        {component.component === "Feefo" ? <Feefo /> : null}

        {component.component === "ReviewsIo" ? <ReviewsIo /> : null}
      </Section>
    ))}
  </Template>
);

export default TemplateOne;
