import React, { useState } from "react";
import ButtonTab from "../ButtonTab/ButtonTab";
import { Card, Col, Row } from "react-bootstrap";

type Props = {
  children?: React.node,
  className?: string,
  activeTab?: number,
};

const ButtonTabs = ({ children, activeTab, className }: Props) => {
  const scrollToContent = tabIndex => {
    let a = document.querySelectorAll(".button-tab-active");
    if (!a.length) return;
    let p = a[0].getBoundingClientRect();
    if (p.y < 0) window.scrollTo(null, window.pageYOffset + p.y);
  };

  const buttonTabs = React.Children.toArray(children).filter(
    child => child.type === ButtonTab
  );

  const [active, setActive] = useState(
    activeTab <= buttonTabs.length ? activeTab - 1 : null
  );

  return (
    <Row
      className={"ButtonTabs justify-content-center no-gutters " + className}
    >
      {buttonTabs.map((buttonTab: ButtonTab, i) => {
        return (
          <React.Fragment key={i}>
            <Col key={i} xs={12} sm={3} className="my-2 card-wrapper">
              <Card
                variant="info"
                data-testid={`contact-page-tab-button-${i}`}
                onClick={() => setActive(i) && scrollToContent(i)}
                data-test="contact-tab"
                className={"h-100" + (active === i ? " button-tab-active" : "")}
              >
                {buttonTab.props.buttonText}
              </Card>
            </Col>
            <Col
              key={i + buttonTabs.length}
              sm={{ span: 12, order: buttonTabs.length + i }}
            >
              {i === active ? (
                <Row className="ButtonTab justify-content-center w-100">
                  {buttonTab}
                </Row>
              ) : null}
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default ButtonTabs;
