import React from "react";
import MapListItem from "../MapListItem/MapListItem";
import { Button, Col, Container, Row } from "react-bootstrap";
import type { TMapListItem } from "../MapListItem/MapListItem";
import { trackEvent } from "../../utils/tealium";
import { capitalizeEachWordOfPathname } from "../../utils/capitalisePathname";

type Props = {
  heading?: string,
  overview?: string,
  mapListItems: TMapListItem[],
  buttonText?: string,
  buttonAction?: string,
};
const MapList = ({
  heading,
  overview,
  mapListItems = [],
  buttonText,
  buttonAction,
}: Props) => (
  <Container className="MapList column-width-container">
    <Row className="align-items-start" noGutters>
      <Col sm={5}>
        <h2>{heading}</h2>
      </Col>
      <Col sm={1} />
      <Col>
        <p className="overview">{overview}</p>
      </Col>
    </Row>
    <Row noGutters>
      <Col sm={12}>
        {mapListItems.map((mapListItem, i) => (
          <MapListItem key={i} {...mapListItem} />
        ))}
      </Col>
    </Row>
    {buttonText ? (
      <Row className="justify-content-center">
        <Col sm="auto" className="action-button">
          <Button variant="secondary" href={buttonAction} onClick={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(window.location.pathname),
                eventAction: "Button Click",
                eventLabel: "Book A Lesson",
              });
            }}>
            {buttonText}
          </Button>
        </Col>
      </Row>
    ) : null}
  </Container>
);

export default MapList;
