// @flow
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { format } from "date-fns";

import SafeLink from "../SafeLink/SafeLink";
import Avatar from "../Avatar/Avatar";

type TInstructorAttributes = {
  key: string,
  value: string,
};
export type TInstructor = {
  name: string,
  attributes?: Array<TInstructorAttributes>,
  url?: string,
  h100?: boolean,
  lastUpdated?: string,
};

const Instructor = ({
  name,
  attributes = [],
  url,
  h100,
  lastUpdated,
}: TInstructor) => {
  return (
    <Card className={`Instructor ${h100 ? "h-100" : ""}`}>
      <Card.Body>
        <Row className="align-items-center avatar-name-row" noGutters>
          <Col md="auto" sm="auto" xs="auto">
            <Avatar name={name} size="sm" />
          </Col>
          <Col md="auto" sm="auto" xs="auto" className="name">
            <div className="name-container">{name}</div>
          </Col>
        </Row>
        {attributes.map(({ key, value }, i) => (
          <Row key={i} className={`attribute ${key}`} noGutters>
            <Col className="key">
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Col>
            <Col className="value">
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </Col>
          </Row>
        ))}
        {lastUpdated && (
          <p className="price-note">
            * Prices correct at the point the page was refreshed{" "}
            {/* Format the date string correctly by replacing the white space between date and time with "T" */}
            {format(new Date(lastUpdated.replace(/\s/g, "T")), "dd/MM/y")}
          </p>
        )}
        {url ? (
          <Row className="more-details">
            <Col>
              <SafeLink to={url}>More details</SafeLink>
            </Col>
          </Row>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default Instructor;
