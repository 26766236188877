import React from "react";
import BlogListItem from "../BlogListItem/BlogListItem";
import { Col, Row } from "react-bootstrap";
import type { TBlogListItem } from "../BlogListItem/BlogListItem";

type Props = {
  posts: TBlogListItem[],
  limit?: number,
  columns?: number,
};
const BlogList = ({ posts = [], limit = 4, columns = 4 }: Props) => {
  return (
    <Col sm={12}>
      <Row>
        {posts
          .filter((post, i) => {
            return !limit || i < limit;
          })
          .map((post, i) => {
            return <BlogListItem key={i} columns={columns} {...post} />;
          })}
      </Row>
    </Col>
  );
};

export default BlogList;
