// @flow
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { svg } from "react-dom";
import SVG from "react-inlinesvg";
import ExplainerStep from "../ExplainerStep/ExplainerStep";

type Props = {
  heroSvg?: React.ComponentType<svg>,
  heroImageUrl?: string,
  title?: string,
  children: React.node,
  steps: Array<ExplainerStep>,
  actionUrl?: string | Function,
};

const Explainer = ({
  heroSvg,
  heroImageUrl,
  title,
  children,
  steps = [],
  actionUrl,
}: Props) => {
  return (
    <Col sm={12} className="Explainer">
      <Row noGutters className="justify-content-center mb-5">
        <Col md={5} className="order-md-last">
          <div className="explainer-hero h-100">
            {heroSvg ? <SVG src={heroSvg} /> : null}
            {!heroSvg && heroImageUrl ? (
              <img src={heroImageUrl} alt={title} />
            ) : null}
          </div>
        </Col>
        <Col md={7}>
          <h6>{title}</h6>
          {children}
          {steps.map((step, i) => (
            <ExplainerStep key={i} step={step} position={i} />
          ))}
        </Col>
      </Row>
      <Row>
        {actionUrl ? (
          <Button variant="secondary" href={actionUrl}>
            BOOK NOW
          </Button>
        ) : null}
      </Row>
    </Col>
  );
};

export default Explainer;
