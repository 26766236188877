import React from "react";
import { Card, Col } from "react-bootstrap";

type Props = {
  image?: string,
  title?: string,
  count?: number,
  children: React.node,
};

const Tarot = ({ image, title, count = 3, children }: Props) => (
  <Col className="Tarot mb-5" md={12 / count}>
    <Card className={"h-100 shadow " + (image ? "with" : "without") + "-image"}>
      {image ? (
        <div
          className="image-wrapper"
          style={{ backgroundImage: `url(${image})` }}
        >
          <Card.Img variant="top" src={image} />
        </div>
      ) : null}
      <Card.Body>
        {title ? <Card.Title as="h3">{title}</Card.Title> : null}
        {children}
      </Card.Body>
    </Card>
  </Col>
);

export default Tarot;
