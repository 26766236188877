import React from "react";
import SVG from "react-inlinesvg";
import star from "./assets/svg/single-star-transparent.svg";

type Props = {
  rating: number,
  sm?: boolean,
};

const TrustPilotStars = ({ rating = 0, sm }: Props) => {
  return (
    <div
      className={`TrustPilotStars rating-${Math.round(rating / 2)} ${
        sm ? "small-stars" : ""
      }`}
    >
      {Array.apply(null, { length: 5 }).map((item, i) => (
        <div key={i} className="rating-star">
          <SVG src={star} />
        </div>
      ))}
    </div>
  );
};

export default TrustPilotStars;
