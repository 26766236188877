import React from "react";

type Props = {
  name: string,
  size: "sm" | "md" | "lg",
};
const Avatar = ({ name, size }: Props) => {
  let initials;
  if (name) {
    let nameParts = name.split(/\s+/);
    initials =
      nameParts[0].slice(0, 1) + nameParts[nameParts.length - 1].slice(0, 1);
  }
  return (
    <div className={`Avatar avatar-${size}`}>
      <div className="outer">
        <div className="inner">
          <span>{initials}</span>
        </div>
      </div>
    </div>
  );
};

export default Avatar;
