import React, { useEffect, useState } from "react";
import Reviews from "../Reviews/Reviews";
import axios from "axios";
import logo from "./assets/logo.svg";
import { formatReviews } from "./helpers";

const ReviewsIo = () => {
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(10);
  const [totalReviews, setTotalReviews] = useState(0);
  useEffect(() => {
    axios("https://api.reviews.co.uk/merchant/latest?store=bsm-co-uk").then(
      result => {
        if (!result.data) return;
        const {
          data: {
            stats: { average_rating, total_reviews },
          },
        } = result;
        setRating(average_rating * 2);
        setTotalReviews(total_reviews);
        setReviews(formatReviews(result.data.reviews));
      }
    );
  }, []);

  return (
    <>
      {reviews.length ? (
        <Reviews
          rating={rating}
          reviews={reviews}
          totalReviews={totalReviews}
          maxPages={5}
          logo={logo}
        />
      ) : null}
    </>
  );
};

export default ReviewsIo;
