import React from "react";

import { Col, Row } from "react-bootstrap";
import SvgIcon from "@material-ui/core/SvgIcon";
import * as Icons from "@material-ui/icons";

import SafeLink from "../SafeLink/SafeLink";
import Map from "../Map/Map";

type TMapAttribute = {
  icon: string,
  title: string,
  url?: string,
};

type TMapAttributeList = {
  title?: string,
  attributes?: TMapAttribute[],
};

type TMapStatistic = {
  key: string,
  value: string | number,
};

type TMapTagList = {
  tag: string,
};

export type TLatLng = {
  lat: number,
  lng: number,
};

export type TMapListItem = {
  address?: string,
  markers?: Array<TLatLng>,
  title?: string,
  category?: string,
  attributeList?: TMapAttributeList,
  statistics?: TMapStatistic[],
  tagList?: TMapTagList[],
  center: TLatLng,
};

type Props = TMapListItem;

const MapListItem = ({
  title,
  category,
  address,
  markers,
  attributeList,
  statistics = [],
  tagList = [],
  center = {
    lat: 51.5074,
    lng: -0.1278,
  },
}: Props) => {
  if (markers && markers.length) {
    center.lat =
      markers
        .map(m => m.lat)
        .map(parseFloat)
        .reduce((a, b) => a + b, 0) / markers.length;
    center.lng =
      markers
        .map(m => m.lng)
        .map(parseFloat)
        .reduce((a, b) => a + b, 0) / markers.length;
  }

  const errorMessage =
    ["production", "stage"].indexOf(process.env.NODE_ENV) !== -1
      ? "Map could be not loaded on this device"
      : "Maps cannot be rendered on IE in development build";

  return (
    <Row className="MapListItem" noGutters>
      <Col sm={5}>
        <div className="map-container-outer">
          <div className="map-container-inner">
            <Map center={center} markers={markers} />
          </div>
        </div>
      </Col>
      <Col sm={1} className="d-none d-sm-block" />
      <Col>
        {title ? <h3>{title}</h3> : null}
        {category ? <h4>{category}</h4> : null}
        {!!tagList.length && (
          <Row className="tags">
            {tagList.map(({ tag }, i) => (
              <span className="tag" key={i}>
                {tag}
              </span>
            ))}
          </Row>
        )}
        {statistics.map(({ key, value, type }, i) => (
          <div key={i} className="statistic">
            <span>{key}:</span>
            <span>{value}</span>
          </div>
        ))}
        {address && <p className="address">{address}</p>}
        {attributeList && (
          <>
            <h3>{attributeList.title}</h3>
            {attributeList.attributes &&
              attributeList.attributes.map(({ icon, title, url }, i) => {
                const Icon = Icons[icon];
                return (
                  <Row key={i} noGutters className="attribute-list-item">
                    <Col xs="auto">
                      <SvgIcon>
                        <Icon />
                      </SvgIcon>
                    </Col>
                    <Col>
                      {url ? (
                        <SafeLink to={url}>
                          <span>{title}</span>
                        </SafeLink>
                      ) : (
                        <span>{title}</span>
                      )}
                    </Col>
                  </Row>
                );
              })}
          </>
        )}
      </Col>
    </Row>
  );
};

export default MapListItem;
