import React from "react";
import { Card, Col } from "react-bootstrap";
import SafeLink from "../SafeLink/SafeLink";
import placeholder from "./assets/placeholder.png";

export type TBlogListItem = {
  imageUrl?: string,
  title: string,
  body: string,
  uri?: string,
  columns?: number,
};

const BlogListItem = ({
  imageUrl,
  title = "",
  body = "",
  uri,
  columns,
}: TBlogListItem) => (
  <Col md={12 / columns} className="BlogListItem">
    <Card className="h-sm-100">
      <SafeLink to={uri}>
        <div
          className="image-wrapper"
          style={{
            backgroundImage: `url(${imageUrl ? imageUrl : placeholder})`,
          }}
        >
          <Card.Img
            variant="top"
            src={imageUrl ? imageUrl : placeholder}
            alt={title}
          />
        </div>
      </SafeLink>
      <Card.Body>
        <SafeLink to={uri}>
          <Card.Title as="h4">{title}</Card.Title>
        </SafeLink>
        <div className="preview">
          {body
            .split(/\n{2,}/)
            .filter((p, i) => i < 1)
            .map((p, i) => (
              <p
                key={i}
                dangerouslySetInnerHTML={{ __html: p.substr(0, 300) }}
              />
            ))}
        </div>
        {uri ? (
          <SafeLink to={uri} className="read-more">
            Read more
          </SafeLink>
        ) : null}
      </Card.Body>
    </Card>
  </Col>
);

export default BlogListItem;
