// @flow
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Instructor from "../Instructor/Instructor";
import type { TInstructor } from "../Instructor/Instructor";
import PaginationArrows from "../PaginationArrows/PaginationArrows";
import { trackEvent } from "../../utils/tealium";
import { capitalizeEachWordOfPathname } from "../../utils/capitalisePathname";

type Props = {
  instructors: Array<TInstructor>,
  buttonText?: string,
  buttonAction?: string,
};
const Instructors = ({ instructors = [], buttonText, buttonAction }: Props) => {
  const rand = instructors.sort(() => Math.random() - 0.5);
  const [instructorsArray, setInstructorsArray] = useState(rand);
  return instructors.length ? (
    <Col>
      <Row className="justify-content-center no-gutters">
        <Col md={10} xs={12} className="Instructors">
          <Row className="instructor-cards">
            {instructorsArray
              .filter((a, i) => i < 3)
              .map((instructor, i) => (
                <Col
                  key={i}
                  sm={6}
                  md={4}
                  className={`${i > 0 && "d-none d-md-block"} ${i > 1 &&
                    "d-sm-block"}`}
                >
                  <Instructor key={i} {...instructor} h100 />
                </Col>
              ))}
          </Row>
        </Col>
        <PaginationArrows
          allowFullWidth
          clickLeftCallback={() => {
            instructorsArray.unshift(instructorsArray.pop());
            setInstructorsArray(instructorsArray.slice(0));
          }}
          clickRightCallback={() => {
            instructorsArray.push(instructorsArray.shift());
            setInstructorsArray(instructorsArray.slice(0));
          }}
        />
      </Row>
      {buttonText ? (
        <Row className="card-button-row">
          <Col className="text-center mt-3">
            <Button variant="secondary" href={buttonAction} onClick={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(window.location.pathname),
                eventAction: "Button Click",
                eventLabel: "Discover Discounts",
              });
              }}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      ) : null}
    </Col>
  ) : null;
};

export default Instructors;
