import React from "react";

type Props = {
  name: string,
};
const InstructorHero = ({ name }: Props) => {
  let initials;
  if (name) {
    let nameParts = name.toUpperCase().split(/\s+/);
    initials =
      nameParts[0].slice(0, 1) + nameParts[nameParts.length - 1].slice(0, 1);
  }

  return (
    <div className="InstructorHero">
      <span>{initials}</span>
    </div>
  );
};

export default InstructorHero;
