// @flow
import React, { Component } from "react";
import Slide from "./Slide/Slide";
import Swipe from "react-easy-swipe";
import PaginationDots from "../Pagination/PaginationDots/PaginationDots";
import PaginationArrows from "../PaginationArrows/PaginationArrows";
import type { TSlide } from "./Slide/Slide";

type Props = {
  slides: Array<TSlide>,
};

class Carousel extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      position: 0,
    };

    this.interval = null;
    this.timeout = null;
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer() {
    this.interval = setInterval(() => this.rotateRight(false), 6000);
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  rotateRight(pauseSlides: boolean = true) {
    this.rotateTo(
      this.state.position + 1 < this.props.slides.length
        ? this.state.position + 1
        : 0,
      pauseSlides
    );
  }

  rotateLeft(pauseSlides: boolean = true) {
    this.rotateTo(
      this.state.position === 0
        ? this.props.slides.length - 1
        : this.state.position - 1,
      pauseSlides
    );
  }

  rotateTo(position: number, pauseSlides: boolean = true) {
    this.setState({ position: position });

    if (!pauseSlides) return;

    this.stopTimer();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.startTimer(), 10000);
  }

  onSwipeMove(position) {
    this.swipedHorizontal = position.x;
    this.swipedVertical = position.y;
  }

  onSwipeEnd() {
    let minSwipe = 100;
    let xYRatio = 2;

    if (Math.abs(this.swipedHorizontal) <= minSwipe) return;
    if (
      Math.abs(this.swipedHorizontal) < Math.abs(this.swipedVertical * xYRatio)
    )
      return;

    this.swipedHorizontal < 0 ? this.rotateRight() : this.rotateLeft();
  }

  clickHandler(index) {
    this.rotateTo(index);
  }

  render() {
    return (
      <div className="carousel-container">
        <div className="Carousel">
          <Swipe
            onSwipeMove={this.onSwipeMove.bind(this)}
            onSwipeEnd={this.onSwipeEnd.bind(this)}
          >
            <div className="Slides">
              {this.props.slides.map((slide, i) => (
                <Slide
                  active={this.state.position === i}
                  key={i}
                  slide={slide}
                />
              ))}
            </div>
          </Swipe>
          {this.props.slides.length > 1 ? (
            <PaginationArrows
              allowFullWidth
              clickLeftCallback={this.rotateLeft.bind(this)}
              clickRightCallback={this.rotateRight.bind(this)}
            />
          ) : null}
        </div>
        <div className="pagination-container">
          <PaginationDots
            number={this.props.slides.length}
            active={this.state.position}
            clickHandler={this.clickHandler.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default Carousel;
